/* You can add global styles to this file, and also import other style files */
@import '@mdi/font/css/materialdesignicons.css';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-menu-panel.profile-menu {
  margin-top: 4px;
}

.mat-tooltip {
  font-size: 13px !important;
  letter-spacing: 0.05em !important;
}

mat-sidenav.mat-drawer:not(.mat-drawer-side) {
  box-shadow: none;
}

.two-fa-settings .mat-chip-list .mat-chip-list-wrapper {
  justify-content: center;
}

//.mat-dialog-container {
//  overflow: hidden !important;
//}

.custom-panel-container .mat-dialog-container {
  overflow: hidden !important;
}
