// The file starts with an underscore (_), indicating that this is a Sass partial file.
// See the Sass documentation for more information about partial files.
// https://sass-lang.com/guide#topic-4
//
// https://material.angular.io/guide/theming-your-components
// https://material.angular.io/guide/theming#defining-a-theme
// https://material.io/design/color/the-color-system.html#color-usage-and-palettes
//
// Note: Must @include this in the theme.scss file for BOTH light and dark themes.

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  //$primary-palette: map.get($color-config, 'primary');

  // If you want to access the accent or warn palettes
  //$accent-palette: map.get($color-config, 'accent');
  //$warn-palette: map.get($color-config, 'warn');

  $is-dark-theme: map.get($color-config, 'is-dark');

  .profile-button-wrapper:hover .profile-button {

    @if $is-dark-theme == true {
      // Example of using the primary color palette from above
      // Read the 500 hue from the primary color palette with opacity.
      //background-color: mat.get-color-from-palette($primary-palette, 500, 0.67);
      background-color: rgba(255, 255, 255, 0.07);
    } @else {
      // Example of using the primary color palette from above
      // Read the 50 hue from the primary color palette with opacity.
      //background-color: mat.get-color-from-palette($primary-palette, 50, 0.87);
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
