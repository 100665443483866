// The file starts with an underscore (_), indicating that this is a Sass partial file.
// See the Sass documentation for more information about partial files.
// https://sass-lang.com/guide#topic-4
// https://material.angular.io/guide/theming-your-components
// https://material.angular.io/guide/theming#defining-a-theme
// https://material.io/design/color/the-color-system.html#color-usage-and-palettes
//
// Note: Must @include this in the theme.scss file for BOTH light and dark themes.

@use 'sass:map';
@use '@angular/material' as mat;

@import '@angular/material/theming';
@include mat-core();

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // If you want to access the primary, accent, or warn palettes
  //$primary-palette: map.get($color-config, 'primary');
  //$accent-palette: map.get($color-config, 'accent');
  //$warn-palette: map.get($color-config, 'warn');

  $is-dark-theme: map.get($color-config, 'is-dark');
  $primary: map-get($theme, primary);

  @if $is-dark-theme == true {
    *::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 12px rgba(255,255,255,0.3);
      background-color: #424242;
      border-radius: 10px;
    }

    *::-webkit-scrollbar
    {
      width: 6px;
      height: 12px;
      background-color: #424242;
    }

    *::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      background-image: -webkit-gradient(linear,
                         left bottom,
                         left top,
                         color-stop(0.44, mat-color($primary, 'lighter')),
                         color-stop(0.72, mat-color($primary)),
                         color-stop(0.86, mat-color($primary, 'darker')));
    }
  } @else {

    *::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 12px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
      border-radius: 10px;
    }

    *::-webkit-scrollbar
    {
      width: 6px;
      height: 12px;
      background-color: #F5F5F5;
    }

    *::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      background-image: -webkit-gradient(linear,
                         left bottom,
                         left top,
                         color-stop(0.44, mat-color($primary, 'lighter')),
                         color-stop(0.72, mat-color($primary)),
                         color-stop(0.86, mat-color($primary, 'darker')));
    }
  }

}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

}
