// The file starts with an underscore (_), indicating that this is a Sass partial file.
// See the Sass documentation for more information about partial files.
// https://sass-lang.com/guide#topic-4
// https://material.angular.io/guide/theming-your-components
// https://material.angular.io/guide/theming#defining-a-theme
// https://material.io/design/color/the-color-system.html#color-usage-and-palettes
//
// Note: Must @include this in the theme.scss file for BOTH light and dark themes.

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // If you want to access the primary, accent, or warn palettes
  //$primary-palette: map.get($color-config, 'primary');
  //$accent-palette: map.get($color-config, 'accent');
  //$warn-palette: map.get($color-config, 'warn');

  $is-dark-theme: map.get($color-config, 'is-dark');

  .active-link {
    @if $is-dark-theme == true {
      background-color: rgba(255, 255, 255, 0.07);
    } @else {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }

  .mat-nav-list .mat-list-item:focus, .mat-action-list .mat-list-item:focus {
    @if $is-dark-theme == true {
      background-color: rgba(255, 255, 255, 0.07);
    } @else {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }

}

//
// This is commented out but left as an example on how to update typography
//
//@mixin typography($theme) {
//  // Get the typography config from the theme.
//  $typography-config: mat.get-typography-config($theme);
//
//  .active-link {
//    font-family: mat.font-family($typography-config);
//  }
//}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  //
  // This is commented out but left as an example on how to update typography
  //
  //$typography-config: mat.get-typography-config($theme);
  //@if $typography-config != null {
  //  @include typography($theme);
  //}
}
